import React from 'react'
import { getAnalyticsContext } from 'analytics'
import { useQueueAddItems } from 'modules/queue'

import { buttonMessages } from 'components/inputs'

import useAddClickHandler from '../../util/useAddClickHandler'

import type { AddToButtonBaseProps } from '../AddToButtonBase/AddToButtonBase'
import AddToButtonBase from '../AddToButtonBase/AddToButtonBase'


export type AddToQueueButtonProps = (
  QueueModule.AddItemInput & QueueModule.AddItemParams
  & Pick<AddToButtonBaseProps, 'className' | 'title' | 'inlineIcon' | 'style' | 'size' | 'width' | 'fullWidth' | 'fullWidthOnMobile' | 'onClick' | 'data-testid' | 'disabled'>
)
& {
  isAdded?: boolean
}

const AddToQueueButton: React.FunctionComponent<AddToQueueButtonProps> = (props) => {
  const {
    className,
    title,
    inlineIcon,
    style,
    size,
    width,
    fullWidth,
    fullWidthOnMobile,
    product,
    metadata,
    force,
    position,
    withNonAuthRedirect,
    withNotification,
    withErrorModals,
    withResubscribeFlow = true,
    withUpgradeFlow,
    withCaseUpsellModal = true,
    withNotificationOnly,
    isAdded: isAddedFromProps,
    disabled,
    onClick,
    'data-testid': dataTestId = 'addToQueueButton',
  } = props

  const [ submit, { isFetching } ] = useQueueAddItems()

  const { isAdded: isAddedAfterClick, handleClick } = useAddClickHandler(async () => {
    // TODO add event - added on 2021-11-26 by maddoger
    const { page, pageType } = getAnalyticsContext()

    await submit({
      product,
      force,
      position,
      metadata: {
        page,
        pageType,
        ...metadata,
      },
    }, {
      withErrorModals,
      withNonAuthRedirect,
      withNotification,
      withResubscribeFlow,
      withUpgradeFlow,
      withCaseUpsellModal,
      withNotificationOnly,
    })

    if (typeof onClick === 'function') {
      onClick()
    }
  })

  /* some products don't have tradingItem when they are out of stock, so we disable the button in this case */
  const isOutOfStock = !product.tradingItemUid

  return (
    <AddToButtonBase
      className={className}
      style={style}
      size={size}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      title={title || buttonMessages.addToQueue}
      inlineIcon={inlineIcon}
      isAdded={isAddedFromProps ?? isAddedAfterClick}
      loading={isFetching}
      isOutOfStock={isOutOfStock}
      disabled={isOutOfStock || disabled}
      onClick={handleClick}
      data-testid={dataTestId}
      data-cnstrc-btn="add_to_queue"
    />
  )
}


export default AddToQueueButton
