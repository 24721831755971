import React from 'react'
import { Message } from 'intl'

import type { ButtonProps } from 'components/inputs'
import { Button, buttonMessages } from 'components/inputs'
import { Icon } from 'components/dataDisplay'


export type AddToButtonBaseProps = Pick<ButtonProps,
'children' | 'className' | 'to' | 'inlineIcon' | 'disabled' | 'loading' | 'width' | 'fullWidth' | 'fullWidthOnMobile' | 'data-testid' | 'data-cnstrc-btn'
> & {
  size?: ButtonProps['size']
  style?: ButtonProps['style']
  title?: string | Intl.Message
  addedMessage?: string | Intl.Message
  isAdded?: boolean
  isOutOfStock?: boolean
  onClick?: () => void
}

const AddToButtonBase: React.FC<AddToButtonBaseProps> = (props) => {
  const {
    children,
    className,
    to,
    style = 'primary',
    size = 48,
    width,
    fullWidth,
    fullWidthOnMobile,
    title,
    inlineIcon,
    addedMessage,
    isAdded,
    isOutOfStock,
    disabled,
    loading,
    onClick,
    'data-testid': dataTestId,
    'data-cnstrc-btn': dataCnstrcBtn,
  } = props

  let content

  if (children) {
    content = children
  }
  else if (isOutOfStock) {
    content = (
      <Message value={buttonMessages.outOfStock} />
    )
  }
  else if (isAdded) {
    const addedColor = style === 'primary' ? 'text-gold-30' : 'text-gold-70'

    content = (
      <div className={`flex items-center justify-center ${addedColor}`}>
        <Message value={addedMessage || buttonMessages.added} />
        <Icon className="ml-4" name="24/checkmark" />
      </div>
    )
  }
  else {
    content = (
      <>
        <Message value={title} />
        {
          Boolean(inlineIcon) && (
            <Icon className="ml-4" name={inlineIcon} />
          )
        }
      </>
    )
  }

  return (
    <Button
      className={className}
      to={to}
      style={style}
      size={size}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      disabled={disabled}
      loading={loading}
      data-testid={dataTestId}
      data-cnstrc-btn={dataCnstrcBtn}
      onClick={onClick}
    >
      {content}
    </Button>
  )
}


export default AddToButtonBase
