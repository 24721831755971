import React from 'react'

import AddToButtonBase, { type AddToButtonBaseProps } from './components/AddToButtonBase/AddToButtonBase'
import AddToQueueButton, { type AddToQueueButtonProps } from './components/AddToQueueButton/AddToQueueButton'
import AddToQueueTryItOldViewButton, { type AddToQueueTryItOldViewButtonProps } from './components/AddToQueueTryItOldViewButton/AddToQueueTryItOldViewButton'
import AddToCartButton, { type AddToCartButtonProps } from './components/AddToCartButton/AddToCartButton'


type QueueVariant = {
  type?: 'queue'
} & AddToQueueButtonProps

type QueueTryItOldViewVariant = {
  type?: 'queueTryItOldView'
} & AddToQueueTryItOldViewButtonProps

type CartVariant = {
  type: 'cart'
} & AddToCartButtonProps

type CustomVariant = {
  type: 'custom'
} & AddToButtonBaseProps

export type AddToButtonPropsWithoutProduct = Omit<QueueVariant, 'product'> | Omit<QueueTryItOldViewVariant, 'product'> | Omit<CartVariant, 'product'> | CustomVariant

export type AddToButtonProps = QueueVariant | CartVariant | CustomVariant | QueueTryItOldViewVariant

// this button isn't very optimal from re-rendering standpoint, but it usually sits in React.memo(ProductCard)
const AddToButton: React.FunctionComponent<AddToButtonProps> = (props) => {
  const { type, ...rest } = props
  const { className, 'data-testid': dataTestId } = rest

  if (type === 'cart') {
    const { product, size, style, width, fullWidth, fullWidthOnMobile, metadata, title, withNotification, withErrorModals, withRedirectToCheckout, onClick } = props

    return (
      <AddToCartButton
        className={className}
        style={style}
        size={size}
        width={width}
        fullWidth={fullWidth}
        fullWidthOnMobile={fullWidthOnMobile}
        product={product}
        metadata={metadata}
        title={title}
        withErrorModals={withErrorModals}
        withNotification={withNotification}
        withRedirectToCheckout={withRedirectToCheckout}
        onClick={onClick}
        data-testid={dataTestId}
      />
    )
  }

  if (type === 'custom') {
    return (
      <AddToButtonBase {...rest} />
    )
  }

  // queue
  const {
    product,
    metadata,
    force,
    position,
    withErrorModals,
    withNotification,
    withNonAuthRedirect,
    withCaseUpsellModal,
    withUpgradeFlow,
    withNotificationOnly,
    onClick,
  } = props

  if (type === 'queueTryItOldView') {
    return (
      <AddToQueueTryItOldViewButton
        className={className}
        product={product}
        metadata={metadata}
        force={force}
        position={position}
        withErrorModals={withErrorModals}
        withNotification={withNotification}
        withNonAuthRedirect={withNonAuthRedirect}
        withNotificationOnly={withNotificationOnly}
        onClick={onClick}
        data-testid={dataTestId}
      />
    )
  }

  const { title, inlineIcon, size, style, width, fullWidth, fullWidthOnMobile, isAdded, disabled } = props

  return (
    <AddToQueueButton
      className={className}
      title={title}
      inlineIcon={inlineIcon}
      style={style}
      size={size}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      product={product}
      metadata={metadata}
      force={force}
      position={position}
      withErrorModals={withErrorModals}
      withNotification={withNotification}
      withNonAuthRedirect={withNonAuthRedirect}
      withCaseUpsellModal={withCaseUpsellModal}
      withUpgradeFlow={withUpgradeFlow}
      withNotificationOnly={withNotificationOnly}
      isAdded={isAdded}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
    />
  )
}


export default AddToButton
