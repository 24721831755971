import { useCallback, useEffect, useRef, useState } from 'react'
import { processError } from 'helpers'


const useAddClickHandler = (onClick?: () => Promise<void>) => {
  const [ isAdded, setAddedState ] = useState(false)

  const timeoutRef = useRef<NodeJS.Timeout>()
  const onClickRef = useRef(onClick)
  onClickRef.current = onClick

  const handleClick = useCallback(async () => {
    try {
      if (typeof onClickRef.current === 'function') {
        await onClickRef.current()
      }

      setAddedState(true)

      timeoutRef.current = setTimeout(() => {
        setAddedState(false)
      }, 3000)
    }
    catch (error) {
      return processError(error)
    }
  }, [])

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current)
  }, [])

  return {
    isAdded,
    handleClick,
  }
}


export default useAddClickHandler
